import { Component, OnInit, ViewChild, Input, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/services/common.service';
import { AppStateService } from '../../app-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { BehaviorService } from '../../behavior.service';
@Component({
  selector: 'app-conditional-report',
  templateUrl: './conditional-report.component.html',
  styleUrls: ['./conditional-report.component.scss']
})
export class ConditionalReportComponent implements OnInit, OnDestroy {
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  @Input() commingDocumentData: any;
  @Input() comingTemplateData: any;

  @ViewChild('reportFileInput', { static: false }) reportFileInput;
  addOfferConditionalReport: boolean = false;
  detailsData: any = ''
  @Output() sendingDocumentData: EventEmitter<any> = new EventEmitter();
  _host = environment.url
  submitted: boolean = false;
  editform:boolean=false
  addSubmitted: boolean = false
  conditionalReportForm: FormGroup;
  addlocationAndDamageSeverityModal: boolean = false
  vehicleDamagesArray: FormArray
  fileToUpload: File = null;
  resposeData: any
  destroy$ = new Subject<boolean>()
  prospectID: any = ''
  selectedTemplateId: any = ''
  isPreSelected: any = ''
  reportID: any = ''
  modalValue: any = '';
  modalType: any = '';
  locationList: any
  damageTypeList: any
  severityList: any;
  modalListParams = [
    'damagetypes',
    'locations',
    'severitys'
  ]

  progress = 0
  templateAdd: boolean;
  templateId: any = '';
  conditionalReportData: any;
  conditionTemplateList: any[];
  viewForm: boolean;
  removeId: any;
  locationId: any = '';
  damageId: any;
  severityId: any;
  routerdata: any
  conditionforpatch:any=false
  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private appStateSvc: AppStateService,
    private _bs:BehaviorService,
  ) {
    this.createForm();
    let url = this.router.url
    this.getConditionTemplateFunc()

    this.commonService.addConditionalModal.subscribe(res => {
      if (res == false) {
        this.conditionalReportForm.reset()
        this.submitted = false
        this.conditionalReportForm.controls.vehicleInfo.setValue('')
      }
    })
    this.commonService.addOfferConditionalReport.subscribe(res => {
      if (res == true) {
        this.addOfferConditionalReport = true
      } else {
        this.addOfferConditionalReport = false
      }
    })
    if (url.match(`/condition-template`)) {

      this.templateAdd = true
      this.conditionalReportForm.controls.name.setValidators([Validators.required])

    } else {
      this.conditionalReportForm.get('name').clearValidators();
      this.templateAdd = false
    }

    this._bs.returnpassconditionalid().subscribe((res:any)=>{

      if(res.id){
    console.log(res);
    this.isPreSelected='yes'
    setTimeout(() => {
      console.log(res,'conditionTemplateList');
      
      
    this.selectedTemplateId=res.id
    },2000);
        // this.prospectingForm.patchValue({'conditionReportTemplateId':res})
        // console.log(this.prospectingForm.value.conditionReportTemplateId);
        
      }else{
        this.isPreSelected='yes'
        setTimeout(() => {
        console.log(this.conditionTemplateList,'this.conditionTemplateList');

          this.selectedTemplateId=this.conditionTemplateList[0].id
          let data={
            id:this.conditionTemplateList[0].id
          }
          this._bs.passconditionalid(data) 
        }, 4000);
  
      }
      
    })




    this._bs.returnsetMakemodelTrim().subscribe((res:any)=>{
      if(res){
        setTimeout(() => {
          if(res.make){
            this.conditionalReportForm.patchValue({'cr_worklist_name':res.make+' | '+res.model+' | '+res.trim+' | '+res.year})
            // this.onSubmit()
          }
   
          
        }, 6000);
      }

    })



    
  }

  ngOnInit() {

    this.routerdata = this.router.url.split('/')
    this.routerdata = this.routerdata[1]
    //console.log('routerdata', this.routerdata);

    this.route.queryParams.subscribe(params => {
      let prospecte_id = params['prospecte_id'];
      this.prospectID = prospecte_id;
      if(this.prospectID){
        this.conditionalReportForm.disable();
        // //console.log("dgjhgdfsjhgdsfjjdfs",this.prospectID)
      }else{
        this.conditionalReportForm.enable()
      }
      

    });
    if (this.router.url.match(`/condition-template`)) {
      this.addOfferConditionalReport = false
    }


    this.vehicleDamagesArray = this.conditionalReportForm.get('vehicleDamagesArray') as FormArray;
    this.modalListParams.forEach(element => {
      this.getdamagePartData(element)
    });

    this.appStateSvc.patchValueConditionReport$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.prospectID = this.appStateSvc.stateData.conditionReportId
      this.getReportData()
      this.viewForm = false
    })

  }
  location(id) {
    this.locationId = id
  }
  damage(id) {
    this.damageId = id

  }
  severityEvent(id) {
    this.severityId = id
  }
  removeDamage(damageId) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this Damage Type?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }
    ).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        let params = new HttpParams().set('?id', damageId)
        this.commonService.deleteRecord('damage/type', params).subscribe((res: any) => {
          if (res.success) {
            this.getdamagePartData(this.modalType)
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.spinner.hide();
        }, ((error => {
          this.spinner.hide()
        })))

      } else {
      }
    });

  }

  Removeseverity(severityId) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this severity?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }
    ).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        let params = new HttpParams().set('?id', severityId)
        this.commonService.deleteRecord('severity', params).subscribe((res: any) => {
          if (res.success) {
            this.getdamagePartData(this.modalType)
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.spinner.hide();
        }, ((error => {
          this.spinner.hide()
        })))

      } else {
      }
    });

  }
  remove(locationId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this Location?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }
    ).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.spinner.show();
        let params = new HttpParams().set('?id', locationId)
        this.commonService.deleteRecord('location', params).subscribe((res: any) => {
          if (res.success) {
            this.getdamagePartData(this.modalType)
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.error.message, 'Error');
          }
          this.spinner.hide();
        }, ((error => {
          this.spinner.hide()
        })))

      } else {
      }
    });

  }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.commingDocumentData && changes.commingDocumentData.currentValue != null && changes.commingDocumentData.currentValue != undefined) {
      this.prospectID = changes.commingDocumentData.currentValue ? changes.commingDocumentData.currentValue.id : ''
      let reportStatus = changes.commingDocumentData.currentValue ? changes.commingDocumentData.currentValue.reportStatus : ''
      if (this.prospectID != '' && this.prospectID != null && this.prospectID != undefined && reportStatus != 0) {
        this.getReportData()


      }
    }
    if (changes.comingTemplateData && changes.comingTemplateData.currentValue != null && changes.comingTemplateData.currentValue != undefined) {

      this.templateId = changes.comingTemplateData.currentValue ? changes.comingTemplateData.currentValue : ''
      //console.log(this.templateId, 'this.templateId');

      if (this.templateId != '' && this.templateId != null && this.templateId != undefined) {
        this.getReportData()
        this.templateAdd = true
      }
    }
   

  }

  get f() { return this.conditionalReportForm.controls; }

  viewFormFun() {
    this.editform=false
    if (this.viewForm) {
      this.viewForm = false
    } else {
      this.viewForm = true
    }
  }
  viewFormFun1(){
    this.editform=true
    // this.templateAdd=true
    this.conditionalReportForm.enable()
  }
  getReportData() {
  console.log('hello 2222');
  
    let apiUrl: any = ''
    let params: any = ''
    if (this.templateId != null && this.templateId != '' && this.templateId != undefined) {
      apiUrl = 'v2/condition/report/templete'
      params = new HttpParams().set('?id', this.templateId)
    } else {
      apiUrl = 'prospecte/condition/report'
      params = new HttpParams().set('?prospecteId', this.prospectID)
    }

    this.commonService.getWithParms(apiUrl, params).subscribe(res => {
      if (res.success) {
        this.resposeData = res.data
        console.log( this.resposeData );
        
        if (this.resposeData && this.resposeData['isPreSelected']) {
          this.isPreSelected = this.resposeData['isPreSelected']
          //console.log(this.isPreSelected ,'this.isPreSelected ');
          
        }

          if(this.resposeData.isPreSelected == 'no'){
            setTimeout(() => {


              this.conditionalReportForm.patchValue({
                vehicleInfo: this.resposeData.vehicleInfo,
                condition_description: this.resposeData.condition_description,
                name: this.resposeData.vehicleAnnouncementsData ? this.resposeData.vehicleAnnouncementsData.name : '',
                checkEngineLight: this.resposeData.vehicleAnnouncementsData.checkEngineLight,
                TitleStatus: this.resposeData.vehicleAnnouncementsData.TitleStatus,
                OdometerState: this.resposeData.vehicleAnnouncementsData.OdometerState,
                TaxiUse: this.resposeData.vehicleAnnouncementsData.TaxiUse,
                Smoker: this.resposeData.vehicleAnnouncementsData.Smoker,
                VINPlateIssue: this.resposeData.vehicleAnnouncementsData.VINPlateIssue,
                AirbagLightOn: this.resposeData.vehicleAnnouncementsData.AirbagLightOn,
                KeyFobs: this.resposeData.vehicleAnnouncementsData.KeyFobs,
                HailDamage: this.resposeData.vehicleAnnouncementsData.HailDamage,
                TitleState: this.resposeData.vehicleAnnouncementsData.TitleState,
                FrameDamage: this.resposeData.vehicleAnnouncementsData.FrameDamage,
                FloodDamage: this.resposeData.vehicleAnnouncementsData.FloodDamage,
                TheftRecovery: this.resposeData.vehicleAnnouncementsData.TheftRecovery,
                PoliceUse: this.resposeData.vehicleAnnouncementsData.PoliceUse,
                GreyMarket: this.resposeData.vehicleAnnouncementsData.GreyMarket,
                GeneralOdor: this.resposeData.vehicleAnnouncementsData.GeneralOdor,
                TirePressureIndicatorWarning: this.resposeData.vehicleAnnouncementsData.TirePressureIndicatorWarning,
                AirbagMissing: this.resposeData.vehicleAnnouncementsData.AirbagMissing,
                Driveable: this.resposeData.vehicleAnnouncementsData.Driveable,
                AirbagsDeployed: this.resposeData.vehicleAnnouncementsData.AirbagsDeployed,
                FireDamage: this.resposeData.vehicleAnnouncementsData.FireDamage,
                LiveryUse: this.resposeData.vehicleAnnouncementsData.LiveryUse,
                HasManuals: this.resposeData.vehicleAnnouncementsData.HasManuals,
                LimitedArbitrationPledge: this.resposeData.vehicleAnnouncementsData.LimitedArbitrationPledge,
                Keys: this.resposeData.vehicleAnnouncementsData.Keys,
              })
  
           
            }, 1500);
          }
        this.reportID = this.resposeData.id
        if (this.resposeData && this.resposeData.conditionReportTemplateId) {

        }

        setTimeout(() => {


          this.conditionalReportForm.patchValue({
            vehicleInfo: this.resposeData.vehicleInfo,
            condition_description: this.resposeData.condition_description,
            name: this.resposeData.vehicleAnnouncementsData ? this.resposeData.vehicleAnnouncementsData.name : '',
            checkEngineLight: this.resposeData.vehicleAnnouncementsData.checkEngineLight,
            TitleStatus: this.resposeData.vehicleAnnouncementsData.TitleStatus,
            OdometerState: this.resposeData.vehicleAnnouncementsData.OdometerState,
            TaxiUse: this.resposeData.vehicleAnnouncementsData.TaxiUse,
            Smoker: this.resposeData.vehicleAnnouncementsData.Smoker,
            VINPlateIssue: this.resposeData.vehicleAnnouncementsData.VINPlateIssue,
            AirbagLightOn: this.resposeData.vehicleAnnouncementsData.AirbagLightOn,
            KeyFobs: this.resposeData.vehicleAnnouncementsData.KeyFobs,
            HailDamage: this.resposeData.vehicleAnnouncementsData.HailDamage,
            TitleState: this.resposeData.vehicleAnnouncementsData.TitleState,
            FrameDamage: this.resposeData.vehicleAnnouncementsData.FrameDamage,
            FloodDamage: this.resposeData.vehicleAnnouncementsData.FloodDamage,
            TheftRecovery: this.resposeData.vehicleAnnouncementsData.TheftRecovery,
            PoliceUse: this.resposeData.vehicleAnnouncementsData.PoliceUse,
            GreyMarket: this.resposeData.vehicleAnnouncementsData.GreyMarket,
            GeneralOdor: this.resposeData.vehicleAnnouncementsData.GeneralOdor,
            TirePressureIndicatorWarning: this.resposeData.vehicleAnnouncementsData.TirePressureIndicatorWarning,
            AirbagMissing: this.resposeData.vehicleAnnouncementsData.AirbagMissing,
            Driveable: this.resposeData.vehicleAnnouncementsData.Driveable,
            AirbagsDeployed: this.resposeData.vehicleAnnouncementsData.AirbagsDeployed,
            FireDamage: this.resposeData.vehicleAnnouncementsData.FireDamage,
            LiveryUse: this.resposeData.vehicleAnnouncementsData.LiveryUse,
            HasManuals: this.resposeData.vehicleAnnouncementsData.HasManuals,
            LimitedArbitrationPledge: this.resposeData.vehicleAnnouncementsData.LimitedArbitrationPledge,
            Keys: this.resposeData.vehicleAnnouncementsData.Keys,
          })

          if (this.resposeData.vehicleDamages && this.resposeData.vehicleDamages.length) {
            console.log('helllo222');
            
            let conditionalReportData = this.resposeData.vehicleDamages
            // //console.log("this.conditionalReportData.id",this.conditionalReportData.id)
            let conditionalReportArray = []
            conditionalReportData.forEach(element => {
  
              conditionalReportArray.push({
                severity: element.severity ? element.severity : '',
                id: element ? element.id : '',
                location: element.location ? element.location : '',
                damageType: element.damageType ? element.damageType : '',
                description: element.description ? element.description : '',
                conditionalReportFile: element.conditionalReportFile,
                progressBar: element.progressBar ? element.progressBar : 'false',
              })
            });
  
  
            conditionalReportData.forEach(x => {
              console.log(x,'x');
              
              this.vehicleDamagesArray.push(this.formBuilder.group(x));
            });
            // if (conditionalReportData.length > 1) {
            //   this.vehicleDamagesArray.removeAt(0)
            // }
          }
  
        }, 1500);
       




       
        if (this.resposeData.conditionReportTemplateId != undefined && this.resposeData.conditionReportTemplateId != null && this.resposeData.conditionReportTemplateId != '') {
          this.selectedTemplateId = this.resposeData.conditionReportTemplateId
        } else {

     

        }


      }
    })
  }

  onAddOfferSubmit() {

    this.submitted = true
    if (!this.conditionalReportForm.invalid) {
      let reportData: any;
      let data: any

      data = {
        vehicleInfo: this.conditionalReportForm.value.vehicleInfo,
        condition_description: this.conditionalReportForm.value.condition_description,
        vehicleAnnouncementsData: {
          checkEngineLight: this.conditionalReportForm.value.checkEngineLight,
          TitleStatus: this.conditionalReportForm.value.TitleStatus,
          OdometerState: this.conditionalReportForm.value.OdometerState,
          TaxiUse: this.conditionalReportForm.value.TaxiUse,
          Smoker: this.conditionalReportForm.value.Smoker,
          VINPlateIssue: this.conditionalReportForm.value.VINPlateIssue,
          AirbagLightOn: this.conditionalReportForm.value.AirbagLightOn,
          KeyFobs: this.conditionalReportForm.value.KeyFobs,
          HailDamage: this.conditionalReportForm.value.HailDamage,
          TitleState: this.conditionalReportForm.value.TitleState,
          FrameDamage: this.conditionalReportForm.value.FrameDamage,
          FloodDamage: this.conditionalReportForm.value.FloodDamage,
          TheftRecovery: this.conditionalReportForm.value.TheftRecovery,
          PoliceUse: this.conditionalReportForm.value.PoliceUse,
          GreyMarket: this.conditionalReportForm.value.GreyMarket,
          GeneralOdor: this.conditionalReportForm.value.GeneralOdor,
          TirePressureIndicatorWarning: this.conditionalReportForm.value.TirePressureIndicatorWarning,
          AirbagMissing: this.conditionalReportForm.value.AirbagMissing,
          Driveable: this.conditionalReportForm.value.Driveable,
          AirbagsDeployed: this.conditionalReportForm.value.AirbagsDeployed,
          FireDamage: this.conditionalReportForm.value.FireDamage,
          LiveryUse: this.conditionalReportForm.value.LiveryUse,
          HasManuals: this.conditionalReportForm.value.HasManuals,
          LimitedArbitrationPledge: this.conditionalReportForm.value.LimitedArbitrationPledge,
          Keys: this.conditionalReportForm.value.Keys
        }


      }


      reportData = this.conditionalReportForm.value.vehicleDamagesArray

      let reportDataList = [];
      reportData.forEach(elem => {
        let elemData = {
          severity: elem.severity ? elem.severity : '',
          location: elem.location ? elem.location : '',
          damageType: elem.damageType ? elem.damageType : '',
          description: elem.description ? elem.description : '',
          conditionalReportFile: elem.conditionalReportFile ? elem.conditionalReportFile : '',
          progressBar: elem.progressBar,
          id: elem ? elem.id : ''
        }
        reportDataList.push(elemData)

      });

      data['vehicleDamages'] = reportDataList


      let responseData = data
      this.commonService.conditionalReportData.next(responseData)
      setTimeout(() => {
        this.submitted = false
        this.commonService.addConditionalModal.next(false)
        this.commonService.addOfferConditionalReport.next(false)
        this.conditionalReportForm.reset()
      }, 1000);
    }
    this.route.queryParams.subscribe(params => {
      let prospecte_id = params['prospecte_id'];
      this.prospectID = prospecte_id;
      if (prospecte_id == undefined) {
        this.prospectID = JSON.parse(localStorage.getItem('prospectingID'));

      }
    });
    this.onSubmit()
  }
  onSubmit() {


    this.submitted = true
    if (!this.conditionalReportForm.invalid) {
      let reportData: any;
      let data: any
      let apiUrl: any = ''
      if (this.templateAdd) {
        apiUrl = 'v2/condition/report/templete'
      } else {
        apiUrl = 'v2/condition/report/templete'
      }
      data = {
        cr_worklist_name:this.conditionalReportForm.value.cr_worklist_name,
        vehicleInfo: this.conditionalReportForm.value.vehicleInfo,
        condition_description: this.conditionalReportForm.value.condition_description ? this.conditionalReportForm.value.condition_description : '',
        vehicleAnnouncementsData: {
          isPreSelected: this.isPreSelected,

          name: this.conditionalReportForm.value.name,
          checkEngineLight: this.conditionalReportForm.value.checkEngineLight,
          TitleStatus: this.conditionalReportForm.value.TitleStatus,
          OdometerState: this.conditionalReportForm.value.OdometerState,
          TaxiUse: this.conditionalReportForm.value.TaxiUse,
          Smoker: this.conditionalReportForm.value.Smoker,
          VINPlateIssue: this.conditionalReportForm.value.VINPlateIssue,
          AirbagLightOn: this.conditionalReportForm.value.AirbagLightOn,
          KeyFobs: this.conditionalReportForm.value.KeyFobs,
          HailDamage: this.conditionalReportForm.value.HailDamage,
          TitleState: this.conditionalReportForm.value.TitleState,
          FrameDamage: this.conditionalReportForm.value.FrameDamage,
          FloodDamage: this.conditionalReportForm.value.FloodDamage,
          TheftRecovery: this.conditionalReportForm.value.TheftRecovery,
          PoliceUse: this.conditionalReportForm.value.PoliceUse,
          GreyMarket: this.conditionalReportForm.value.GreyMarket,
          GeneralOdor: this.conditionalReportForm.value.GeneralOdor,
          TirePressureIndicatorWarning: this.conditionalReportForm.value.TirePressureIndicatorWarning,
          AirbagMissing: this.conditionalReportForm.value.AirbagMissing,
          Driveable: this.conditionalReportForm.value.Driveable,
          AirbagsDeployed: this.conditionalReportForm.value.AirbagsDeployed,
          FireDamage: this.conditionalReportForm.value.FireDamage,
          LiveryUse: this.conditionalReportForm.value.LiveryUse,
          HasManuals: this.conditionalReportForm.value.HasManuals,
          LimitedArbitrationPledge: this.conditionalReportForm.value.LimitedArbitrationPledge,
          Keys: this.conditionalReportForm.value.Keys
        }

      }
      if (this.templateAdd && this.selectedTemplateId) {
        data['name'] = this.conditionalReportForm.value.name
      }
      reportData = this.conditionalReportForm.value.vehicleDamagesArray

      let reportDataList = [];
      reportData.forEach(elem => {
        let elemData = {
          severity: elem.severity ? elem.severity : '',
          location: elem.location ? elem.location : '',
          damageType: elem.damageType ? elem.damageType : '',
          description: elem.description ? elem.description : '',
          conditionalReportFile: elem.conditionalReportFile ? elem.conditionalReportFile : '',
          progressBar: elem.progressBar,
          id: elem ? elem.id : ''
        }
        reportDataList.push(elemData)

      });
      data['vehicleDamages'] = reportDataList
      if (!this.templateAdd) {

        data['prospecteId'] = this.prospectID
      }


      if (this.reportID != '' && this.reportID != null && this.reportID != undefined || this.selectedTemplateId) {
        data['id'] =this.selectedTemplateId?this.selectedTemplateId: this.reportID

        this.commonService.updatePut(apiUrl, data, '').subscribe(res => {
          if (res.success) {
            this.toastr.success(res.message, 'Success')
            this.commonService.addConditionalModal.next(false)

            let datas: {
              modal: false
            }
            this.sendingDocumentData.emit(datas)
            this.conditionalReportForm.reset()
            this.conditionalReportForm.controls.vehicleInfo.setValue('')
            // this.vehicleDamagesArray.clear()
            if(this.routerdata=='accepted-requests-form'){
              setTimeout(() => {
                
              }, 2000);
              this.singleTemplateData( this.selectedTemplateId)
            }
            this.submitted = false
          } else {
            this.toastr.error(res.error, 'Error')
          }
        }, error => {
          this.toastr.error(error.message)
        })
      } else {
        this.commonService.add(apiUrl, data).subscribe(res => {
          if (res.success) {
            this.toastr.success(res.message, 'Success')
            this.commonService.addConditionalModal.next(false)

            let datas: {
              modal: false
            }

            this.sendingDocumentData.emit(datas)
            this.conditionalReportForm.reset()
            if(this.routerdata=='accepted-requests-form'){
              this.singleTemplateData( this.selectedTemplateId)
            }
            this.submitted = false
          } else {
            this.toastr.error(res.error, 'Error')
          }
        }, error => {
        })
      }




    }
  }
  getConditionTemplateFunc() {

    this.conditionTemplateList = [];
    let data = new HttpParams().set('?page', '1').set('count', '2000')
    this.commonService.getWithParms('v2/condition/report/templetes', data).subscribe((response) => {
      if (response.data.length == 0) {
        this.conditionTemplateList = [];

      } else {

        this.conditionTemplateList = response.data
console.log(this.conditionTemplateList,'conditionTemplateList');

      }
    }, error => {
    });
  }
  changeConditionTypeFunc() {
    
    this.conditionalReportData = ''
    this.conditionalReportForm.reset();
   
  }
  sendTemplateData() {
    let reportData: any;
    this.submitted = true
    let apiUrl = 'condition/report';


    let data = {
      vehicleInfo: this.conditionalReportForm.value.vehicleInfo,
      isPreSelected: this.isPreSelected,
      condition_description: this.conditionalReportForm.value.condition_description ? this.conditionalReportForm.value.condition_description : '',
      vehicleAnnouncementsData: {
        name: this.conditionalReportForm.value.name,
        checkEngineLight: this.conditionalReportForm.value.checkEngineLight,
        TitleStatus: this.conditionalReportForm.value.TitleStatus,
        OdometerState: this.conditionalReportForm.value.OdometerState,
        TaxiUse: this.conditionalReportForm.value.TaxiUse,
        Smoker: this.conditionalReportForm.value.Smoker,
        VINPlateIssue: this.conditionalReportForm.value.VINPlateIssue,
        AirbagLightOn: this.conditionalReportForm.value.AirbagLightOn,
        KeyFobs: this.conditionalReportForm.value.KeyFobs,
        HailDamage: this.conditionalReportForm.value.HailDamage,
        TitleState: this.conditionalReportForm.value.TitleState,
        FrameDamage: this.conditionalReportForm.value.FrameDamage,
        FloodDamage: this.conditionalReportForm.value.FloodDamage,
        TheftRecovery: this.conditionalReportForm.value.TheftRecovery,
        PoliceUse: this.conditionalReportForm.value.PoliceUse,
        GreyMarket: this.conditionalReportForm.value.GreyMarket,
        GeneralOdor: this.conditionalReportForm.value.GeneralOdor,
        TirePressureIndicatorWarning: this.conditionalReportForm.value.TirePressureIndicatorWarning,
        AirbagMissing: this.conditionalReportForm.value.AirbagMissing,
        Driveable: this.conditionalReportForm.value.Driveable,
        AirbagsDeployed: this.conditionalReportForm.value.AirbagsDeployed,
        FireDamage: this.conditionalReportForm.value.FireDamage,
        LiveryUse: this.conditionalReportForm.value.LiveryUse,
        HasManuals: this.conditionalReportForm.value.HasManuals,
        LimitedArbitrationPledge: this.conditionalReportForm.value.LimitedArbitrationPledge,
        Keys: this.conditionalReportForm.value.Keys
      }

    }
    if (this.isPreSelected == 'yes') {
      data['conditionReportTemplateId'] = this.selectedTemplateId
    }
    if (!this.templateAdd) {

      if (this.prospectID != undefined) {
        data['prospecteId'] = this.prospectID

      } else {
        this.prospectID = JSON.parse(localStorage.getItem('prospectingID'));
        data['prospecteId'] = this.prospectID

      }

    }
    if (this.templateAdd) {
      data['name'] = this.conditionalReportForm.value.name
    }
    reportData = this.conditionalReportForm.value.vehicleDamagesArray

    let reportDataList = [];
    reportData.forEach(elem => {
      let elemData = {
        severity: elem.severity ? elem.severity : '',
        location: elem.location ? elem.location : '',
        damageType: elem.damageType ? elem.damageType : '',
        description: elem.description ? elem.description : '',
        conditionalReportFile: elem.conditionalReportFile ? elem.conditionalReportFile : '',
        progressBar: elem.progressBar,
        id: elem ? elem.id : ''
      }
      reportDataList.push(elemData)

    });
    data['vehicleDamages'] = reportDataList
    if (!this.templateAdd) {

      data['prospecteId'] = this.prospectID
    }

    if (this.reportID != '' && this.reportID != null && this.reportID != undefined) {
      data['id'] = this.reportID

      this.commonService.updatePut(apiUrl, data, '').subscribe(res => {
        if (res.success) {
          this.toastr.success(res.message, 'Success')
          this.commonService.addConditionalModal.next(false)

          let datas: {
            modal: false
          }
          this.sendingDocumentData.emit(datas)
          this.conditionalReportForm.reset()
          this.conditionalReportForm.controls.vehicleInfo.setValue('')
          this.submitted = false
        } else {
          this.toastr.error(res.error, 'Error')
        }
      }, error => {
        this.toastr.error(error.message)
      })
    } else {
      this.commonService.add(apiUrl, data).subscribe(res => {
        if (res.success) {
          this.toastr.success(res.message, 'Success')
          this.commonService.addConditionalModal.next(false)

          let datas: {
            modal: false
          }

          this.sendingDocumentData.emit(datas)
          this.conditionalReportForm.reset()
          this.submitted = false
        } else {
          this.toastr.error(res.error, 'Error')
        }
      }, error => {
        this.toastr.error(error.message)
      })
    }


    setTimeout(() => {

      this.commonService.addConditionalModal.next(false)

      let datas: {
        modal: false
      }

      this.sendingDocumentData.emit(datas)
      this.submitted = false
    }, 1000);
    // }

  }
  removeConditionalReport(i) {
    this.vehicleDamagesArray.removeAt(i)
  }
  uploadconditionalReportFunc(files: FileList, i) {
    if (files && files[0]) {
      var filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {
        this.fileToUpload = files.item(i)
      }
    }
    let modalName = 'conditionalReports'
    if (this.fileToUpload) {
      let docData = []
      docData = this.vehicleDamagesArray.controls[i]['controls']['conditionalReportFile'].value;
      this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('true')

      this.commonService.uploadOnlyImage(this.fileToUpload, modalName).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            if (event.body.success) {

              this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('false')

              docData = event.body.data.imagePath

              this.vehicleDamagesArray.controls[i]['controls']['conditionalReportFile'].patchValue(docData)

              this.reportFileInput.nativeElement.value = '';
              this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('false')

            } else {
              window.scrollTo(0, 0);
              this.toastr.error(event.body.error.message, 'Error');
              this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('false')

            }
            setTimeout(() => {
              this.progress = 0;
              this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('false')

            }, 100);

        }
      }, err => {
        this.vehicleDamagesArray.controls[i]['controls']['progressBar'].patchValue('false')

      });
    }
  }

  addModalDataModal(value) {
    this.modalType = value
    this.addlocationAndDamageSeverityModal = true;
    this.addSubmitted = false;

  }
  closeModalDataModal() {

    this.addlocationAndDamageSeverityModal = false;
    this.addSubmitted = false;
  }
  modalDataSubmit() {
    this.addSubmitted = true;
    if (this.addSubmitted && this.modalValue !== '') {
      this.spinner.show();
      let data = {
        name: this.modalValue,
        model: this.modalType
      }

      this.commonService.add('record', data).subscribe(res => {

        if (res.code == 200) {
          this.toastr.success(res.message, 'Success');

          this.getdamagePartData(this.modalType)
          this.modalValue = '';
          this.addSubmitted = false;
          this.addlocationAndDamageSeverityModal = false;

        } else {
          this.toastr.error(res.error.message, 'Error');
        }
        this.spinner.hide();
      },
        error => {
          this.spinner.hide();
        });

    }
  }
  getdamagePartData(value) {
    let data = new HttpParams().set('?model', value).set('isDeleted', 'false')
    this.commonService.getWithParms('records', data).subscribe((response) => {
      if (response.success) {
        if (response.data.length == 0) {
          this.locationList = [];
          this.damageTypeList = [];
          this.severityList = [];
        } else {
          if (value == 'locations') {
            this.locationList = response.data
          } else if (value == 'damagetypes') {
            this.damageTypeList = response.data
          } else if (value == 'severitys') {
            this.severityList = response.data
          }
        }
      }

    }, error => {
      this.spinner.hide();
    });
  }
  createForm() {
    this.conditionalReportForm = this.formBuilder.group({
      name: [''],
      description: [''],
      vehicleInfo: ['', [Validators.required]],
      condition_description: [''],
      checkEngineLight: [''],
      TitleStatus: [''],
      OdometerState: [''],
      TaxiUse: [''],
      Smoker: [''],
      VINPlateIssue: [''],
      AirbagLightOn: [''],
      KeyFobs: [''],
      HailDamage: [''],
      TitleState: [''],
      FrameDamage: [''],
      FloodDamage: [''],
      TheftRecovery: [''],
      PoliceUse: [''],
      GreyMarket: [''],
      GeneralOdor: [''],
      TirePressureIndicatorWarning: [''],
      AirbagMissing: [''],
      Driveable: [''],
      AirbagsDeployed: [''],
      FireDamage: [''],
      LiveryUse: [''],
      HasManuals: [''],
      LimitedArbitrationPledge: [''],
      Keys: [''],
      vehicleDamagesArray: this.formBuilder.array([]),
      cr_worklist_name:['']

    })
  }

  addConditionalReport(id) {
    console.log(id,'id');
    
    // //console.log("this.conditionalReportData.id",this.conditionalReportData.id)
    this.vehicleDamagesArray.push(this.createConditionalReport(id));
  
  }

  createConditionalReport(id):
    FormGroup {
    return this.formBuilder.group({
      severity: [''],
      location: [''],
      damageType: [''],
      description: [''],
      conditionalReportFile: [''],
      progressBar: [''],
      // id: this.conditionalReportData.id
      id:id
    })

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe()
    this.commonService.addConditionalModal.next(false)
    this.commonService.addOfferConditionalReport.next(false)
    this.templateAdd = false

  }

  removeImage(type, image, i) {

    let object = {
      Imagename: image,
      type: 'buyprospective'
    }
    this.spinner.show();
    this.commonService.deleteImage(object).subscribe((res: any) => {
      if (res.success) {

        if (type == 'conditionalReportFile') {

          this.vehicleDamagesArray.controls[i]['controls']['conditionalReportFile'].patchValue('')



        }
        this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toastr.error(res.error.message, 'Error');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  singleTemplateData(id) {
console.log(id);


    if (this.conditionalReportForm.value.vehicleDamagesArray && this.conditionalReportForm.value.vehicleDamagesArray.lenght)
      this.conditionalReportForm.value.vehicleDamagesArray.map((item: any, index: any) => {
        this.vehicleDamagesArray.removeAt(index)
      })
    let params = new HttpParams().set('?id', id?id:this.selectedTemplateId?this.selectedTemplateId:'')

    this.commonService.getWithParms('v2/condition/report/templete', params).subscribe((response) => {

      this.conditionalReportData = response.data
      //console.log("this.conditionalReportData.id",this.conditionalReportData.id)
      let data={
        id:id
      }
this._bs.passconditionalid(data)

      setTimeout(() => {

        this.vehicleDamagesArray.clear()
        this.conditionalReportForm.patchValue({

          name: this.conditionalReportData.name ? this.conditionalReportData.name : this.conditionalReportData.vehicleAnnouncementsData.name,
          vehicleInfo: this.conditionalReportData.vehicleInfo,
          condition_description: this.conditionalReportData.condition_description,
          checkEngineLight: this.conditionalReportData.vehicleAnnouncementsData.checkEngineLight,
          TitleStatus: this.conditionalReportData.vehicleAnnouncementsData.TitleStatus,
          OdometerState: this.conditionalReportData.vehicleAnnouncementsData.OdometerState,
          TaxiUse: this.conditionalReportData.vehicleAnnouncementsData.TaxiUse,
          Smoker: this.conditionalReportData.vehicleAnnouncementsData.Smoker,
          VINPlateIssue: this.conditionalReportData.vehicleAnnouncementsData.VINPlateIssue,
          AirbagLightOn: this.conditionalReportData.vehicleAnnouncementsData.AirbagLightOn,
          KeyFobs: this.conditionalReportData.vehicleAnnouncementsData.KeyFobs,
          HailDamage: this.conditionalReportData.vehicleAnnouncementsData.HailDamage,
          TitleState: this.conditionalReportData.vehicleAnnouncementsData.TitleState,
          FrameDamage: this.conditionalReportData.vehicleAnnouncementsData.FrameDamage,
          FloodDamage: this.conditionalReportData.vehicleAnnouncementsData.FloodDamage,
          TheftRecovery: this.conditionalReportData.vehicleAnnouncementsData.TheftRecovery,
          PoliceUse: this.conditionalReportData.vehicleAnnouncementsData.PoliceUse,
          GreyMarket: this.conditionalReportData.vehicleAnnouncementsData.GreyMarket,
          GeneralOdor: this.conditionalReportData.vehicleAnnouncementsData.GeneralOdor,
          TirePressureIndicatorWarning: this.conditionalReportData.vehicleAnnouncementsData.TirePressureIndicatorWarning,
          AirbagMissing: this.conditionalReportData.vehicleAnnouncementsData.AirbagMissing,
          Driveable: this.conditionalReportData.vehicleAnnouncementsData.Driveable,
          AirbagsDeployed: this.conditionalReportData.vehicleAnnouncementsData.AirbagsDeployed,
          FireDamage: this.conditionalReportData.vehicleAnnouncementsData.FireDamage,
          LiveryUse: this.conditionalReportData.vehicleAnnouncementsData.LiveryUse,
          HasManuals: this.conditionalReportData.vehicleAnnouncementsData.HasManuals,
          LimitedArbitrationPledge: this.conditionalReportData.vehicleAnnouncementsData.LimitedArbitrationPledge,
          Keys: this.conditionalReportData.vehicleAnnouncementsData.Keys,
          vehicleDamagesArray: this.conditionalReportData.vehicleDamages
        })
        if (this.conditionalReportData.vehicleDamages && this.conditionalReportData.vehicleDamages.length > 0) {
          let conditionalReportData = this.conditionalReportData.vehicleDamages

          let conditionalReportArray = []
          conditionalReportData.forEach(element => {
            conditionalReportArray.push({
              id:  id?id:this.selectedTemplateId?this.selectedTemplateId:'',
              severity: element.severity ? element.severity : '',
              location: element.location ? element.location : '',
              damageType: element.damageType ? element.damageType : '',
              description: element.description ? element.description : '',
              conditionalReportFile: element.conditionalReportFile,
              progressBar: element.progressBar ? element.progressBar : 'false',
            })
          });
          conditionalReportArray.forEach(x => {
            console.log(x,'x');
            
            this.vehicleDamagesArray.push(this.formBuilder.group(x));
          });
          // if (conditionalReportData.length > 1) {
          //   this.vehicleDamagesArray.removeAt(0)
          // }
          this.addOfferConditionalReport = false
        }
      }, 2000);


    });
  }
}
